form {
  background-color: white;
  border: 1px solid #333333;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-flow: column nowrap;
  width: auto;
  max-width: 340px;
  box-sizing: border-box;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .alignLeft {
    text-align: left;
  }

  .txt {
    padding: 0px !important;

    :focus {
      outline: none !important;
    }
  }

  a {
    font-size: 12px;
    position: absolute;
    font-weight: normal;
  }

  .Login-header {
    font-size: 22px;
    color: #333333;

  }
}

.vendor-sign-in {
  position: relative !important;
  width: 280px;
  height: 35px;
  background: #577BFA;
  color: white;
  border: unset;
  border-color: #577BFA;
  margin-top: 2rem;
}

.tryAgain {
  width: 280px;
  height: 35px;
  color: white;
  border: unset;
  border-color: #577BFA;
  margin-top: 2rem;
}

.lbl {
  font-weight: normal !important;
  font-size: 10px;
  text-align: left;
}