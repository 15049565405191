$base-height: 48px;
.sidebar {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: calc(100vh - 95px) !important;
    z-index: 100;
    width: -webkit-fill-available;
    min-width: 210px;
    background-color: white;
    box-shadow: 10px 0 5px -2px #dfdfdf;
}

.nav-link {
   // font-family: roboto !important;
    font-size: 14px !important;
    color: #333333 !important;
    position: relative;

    img {
        padding-right: 8px;
        position: relative;
        height: calc($base-height * 0.4);
        width: auto;
    }
}

.nav-link-active {
    background-color: #ccc;
    border-left: #577BFA 5px solid;
}

.navbar {
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 0 !important;
}

#sidebar-wrapper {
    // min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

@media (min-width: 576px) {
    .navbar-expand-sm .navbar-nav {
        flex-direction: column !important;
        min-width: 210px !important;
    }
}

@media (min-width: 576px) {

    .container,
    .container-sm {
        max-width: inherit !important;
        padding-left: 0 !important;
    }
}

@media (min-width: 300px) and (max-width: 575px) {
    .navbar {
        --bs-navbar-padding-x: 0;
        --bs-navbar-padding-y: 0.5rem !important;
    }
}