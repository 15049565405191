.pdf-viewer-dialog {
    height: 95vh;
    .p-dialog-content {
        padding-bottom: 0.25rem !important;
    }
    .p-dialog-header {
        padding: 0.5rem 1.5rem !important;
    }
}
.admin-edit-dialog {
    width: 30vw !important;
}