.dataTable {
    font-size: 12px !important;

    .p-button,
    .pi,
    .p-inputtext,
    .p-paginator-element {
        font-size: 12px !important;
    }

    .p-button {
        padding: 0.25rem 1.25rem !important;
    }

    .p-button-link {
        padding: 0 !important;
        background: none !important;
        border: none !important;
    }

    .p-button-outlined {
        border: 1px solid #577BFA !important;
        background: none !important;
    }

    .p-datatable .p-datatable-tbody>tr.p-highlight {
        background: unset !important;
        color: unset !important;
    }

    .p-datatable tbody, tfoot, tr, td {
        font-size: 10px;
        padding: 0rem 0.5rem !important;
        max-width: 150px !important;
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;   
    }   
    .p-datatable thead, th {
        font-size: 10px;
        padding: 0rem 0.5rem !important;
        max-width: 150px !important;
        white-space: normal !important;
        overflow: hidden;
        text-overflow: ellipsis;   
    }
    
    .tag-css{
        font-size: 10px !important;
    }
    
    .delete-icon {
        cursor: pointer;
    }

    .p-paginator .p-paginator-first,
    .p-paginator .p-paginator-prev,
    .p-paginator .p-paginator-next,
    .p-paginator .p-paginator-last,
    .p-paginator .p-paginator-pages .p-paginator-page,
    .p-paginator .p-paginator-current {
        height: 1rem !important;
        margin: 0 0.1rem;
        font-size: 10px !important;
        padding: 0px !important;
    }

    .p-paginator .p-dropdown {
        height: 2rem;
        .p-inputtext {    
            padding: 0 0.5rem;     
        }
    }
    
    .p-paginator.p-component {
        padding: 0 .5rem;
    }

    .p-inputtext {
        padding: 0.5rem !important;
    }

    .p-multiselect .p-multiselect-label {
        padding: 0.5rem 0.75rem;
    }
}