$base-height: 48px;
.backgroundImg{
    background-image: url("../../../assets/GSE_LandingBackground.png");   
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    //height: calc(100vh - 85px); 
    //width: calc(100vw - 12px);
    height: 100vh; 
    width: 100vw;
    background-position: center; 
}
.web-app-bar {
    display: flex;
   // background-color: #333333;
    height: 82px;
    flex-shrink: 0;
    width: 100%;
    flex-flow: unset;
    align-items: center;
    border-bottom: 1.5px solid white;   

    .quickLauncher {
        height: $base-height;
        width: $base-height;
        flex-shrink: 0;
        flex-grow: 0;
    }
    .quickLauncherIcon {
        color: white;
        font-size: calc($base-height / 3);
    }
    .appLogo {
        height:  calc($base-height * 0.8);
        width: auto;
    }
    .appLogoContainer {
        width: 233px;
        display: flex;
        justify-content: center;
    }
    .appTitle {
        color: white;
        font-weight: 600;
        //font-family: 'Roboto';
        font-size:  22px;
       // line-height: 24;
        -webkit-font-smoothing: antialiased;
        text-decoration: none;
    }
    @media (min-width: 300px) and (max-width: 575px){
        .appTitle {
            color: white;
            font-weight: 600;
            //font-family: 'Roboto';
            font-size:  12px;
           // line-height: 24;
            -webkit-font-smoothing: antialiased;
            text-decoration: none;
        }
    }
    .user-name-section {
        text-align: end;
        .user-name {            
            color: white;
            font-size: 18px;
            line-height: 20px; 
        }
    }
}