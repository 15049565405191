.dashboard-edit {
  p {
    font-size: 10px !important;
    padding: 35px;
  }

  .p-button {
    background: #577BFA !important;
    border: 1px solid #577BFA !important;
    padding: 5px 20px 5px !important;
    font-size: 11px !important;
    max-width: 100px;
    height: 30px;
    position: relative;
  }

  .p-button.p-button-link {
    color: #4338CA;
    background: transparent !important;
    border: transparent !important;
    max-width: unset;
    width: unset;
  }

  .p-dialog-footer {
    text-align: center !important;
  }

  .p-inputtext,
  .p-autocomplete,
  .p-dropdown {
    font-size: 10px !important;
    padding: 5px;
    border-radius: 0;
    width: 100%;
  }

  .p-dropdown .p-inputtext {
    padding-top: 0;
    padding-bottom: 0;
  }

  form {
    position: relative;
    display: contents;

    .grpdiv {
      padding: 2vh;
    }

    .label {
      font-size: 9px !important;
    }

    .grplabel {
      font-size: 9px !important;
      color: #828282;
    }

    .p-disabled {
      background-color: #e6e5ed;
    }

    .footer {
      .p-button {
        width: 6rem;
      }
    }

    .p-button {
      width: 3rem;
      justify-content: center;
      .p-button-icon-left {
        margin-right: 0;
      }
    }

    .file-upload-btn {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .p-fileupload-content {
      display: none;
    }

    .p-fileupload-choose {
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
    }
  }
}

.user-entry {
  border-left: 6px solid rgb(0, 174, 255);
  background-color: rgb(241, 234, 234);
}

.delete-entry {
  cursor: pointer;
}

.dashboard-edit-dialog {
  .p-dialog-title,
  .p-dialog-header-close-icon {
    font-size: 0.75rem !important;
  }

  .p-dialog-header {
    padding: 0.25rem 1.5rem !important;
  }

  .p-dialog-content {
    padding: 0 1.5rem 0.25rem 1.5rem !important;
  }

  small,
  .small {
    font-size: 0.64em;
  }
   .span-content{
      font-size: 8px !important;
      color: #828282;
      float: right !important;
   }
}