$base-height: 48px;
.dashboard-app-bar {
    display: flex;
    background-color: white;
    height: 50px;
    flex-shrink: 0;
    width: 100%;
    flex-flow: unset;
    align-items: center;
    border-bottom: 1.5px solid #e5e2e2;  
    box-shadow: 0px 15px 10px -15px #8f8f8f; 
   
    .quickLauncher {
        height: $base-height;
        width: $base-height;
        flex-shrink: 0;
        flex-grow: 0;
    }
    .quickLauncherIcon {
        color: white;
        font-size: calc($base-height / 3);
    }
    .appLogo {
        height:  calc($base-height * 0.8);
        width: auto;
    }
    .appIcon {
        height: calc($base-height * 0.3);
        width: auto;
        margin-right: 2%;
    }
    .appLogoContainer {
        width: 170px;
        display: flex;
        justify-content: center;
    }
    .dashboard-appTitle {
        color: #393939;
        font-weight: 600;
        //font-family: 'Roboto';
        font-size:  20px;
        //line-height: 24;
        -webkit-font-smoothing: antialiased;
        position: relative;
        text-decoration: none;
    }
    
    @media (min-width: 300px) and (max-width: 575px){
        .dashboard-appTitle {
            color: #393939;
            font-weight: 600;
            //font-family: 'Roboto';
            font-size:  10px;
            //line-height: 24;
            -webkit-font-smoothing: antialiased;
            position: relative;
        }
    }

    .user-name-section {
        text-align: end;
              
        .user-name {            
            color: #393939;
            font-size: 12px;
            line-height: 20px; 
            //padding-left: 25px;
            padding-right: 10px;
        }
        .dropdown-toggle{
            border: none !important;
        }
        .dropdown-toggle::after {
            display: none !important;           
          }
        .dropdown-menu
        {           
            padding: 0 !important;
            color: #393939;
            font-size: 12px;
            
        }     
        
        .dropdown-item {
            font-weight: 600 !important;
            }
    }
}

