.frm {
  font-size: 10px !important;
  width: 100%;
  height: 70%;
  padding: 20px !important;
}

.lbl {
  font-size: 12px !important;
  font-weight: bold !important;
}

.spn {
  font-size: 10px !important;
  font-weight: normal !important;
}

.spnMandatory {
  color: red;
}

.emailtextarea {
  overflow-y: scroll !important;
  resize: vertical !important;
}

.lbl-error {
  font-size: 10px !important;
  color: red;
  text-align: left !important;
}