.access-popup {
  display: flex;
  flex-flow: column nowrap;
  width: 65vh;  
  box-sizing: border-box; 
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  p{
    font-size: 14px !important;
    padding: 35px;
  }
  .p-button {
    max-width: 200px;
  }
}

