.p-card-title{
    font-size: 15px !important;
}
.p-btn
{
    font: 10px !important;
    min-width: 250px;
    margin-top: 2px !important;
}

.admin-container {
    .link-option {
        justify-content: center;
        text-decoration: none;
        font-weight: bold;
    }
}